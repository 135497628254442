// EasterEggHunt.tsx
import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { POLL_EASTER_EGG_QUERY } from "@/graphql/queries/easterEggQueries";
import { CLAIM_EASTER_EGG_MUTATION } from "@/graphql/mutations/easterEggMutations";
import {
  EggProps,
  PollEasterEggResult,
  ClaimEasterEggResult,
  ClaimEasterEggVars,
  Egg,
} from "@/types/easterEggTypes";
import EasterEggSuccessModal from "./EasterEggSuccessModal";

// The EggComponent displays the egg image at a random position.
const EggComponent: React.FC<EggProps> = ({ egg, onClick }) => {
  const [position, setPosition] = useState<{ top: string; left: string }>({
    top: "50%",
    left: "50%",
  });

  useEffect(() => {
    // Randomize position between 10% and 80% of the viewport.
    const top = Math.floor(Math.random() * 70) + 10;
    const left = Math.floor(Math.random() * 70) + 10;
    setPosition({ top: `${top}%`, left: `${left}%` });
  }, []);

  return (
    <div
      onClick={onClick}
      className="absolute cursor-pointer select-none z-500"
      style={{ top: position.top, left: position.left }}
    >
      <img src={egg.imageUrl} alt={egg.name} className="h-12 w-12" />
    </div>
  );
};

const EasterEggHunt: React.FC = () => {
  // Poll the backend every 5 minutes.
  const { data, loading, refetch } = useQuery<PollEasterEggResult>(
    POLL_EASTER_EGG_QUERY,
    {
      pollInterval: 300000, // 5 minutes = 300,000ms
      fetchPolicy: "no-cache",
    }
  );

  const [claimEasterEgg] = useMutation<ClaimEasterEggResult, ClaimEasterEggVars>(
    CLAIM_EASTER_EGG_MUTATION
  );
  const [eggData, setEggData] = useState<{ egg: Egg; token: string } | null>(
    null
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && data) {
      if (
        data.pollEasterEgg &&
        data.pollEasterEgg.egg &&
        data.pollEasterEgg.token
      ) {
        setEggData({
          egg: data.pollEasterEgg.egg,
          token: data.pollEasterEgg.token,
        });
      } else {
        setEggData(null);
      }
    }
  }, [data, loading]);

  const handleEggClick = async () => {
    if (!eggData) return;
    try {
      await claimEasterEgg({
        variables: { instanceId: eggData.token },
      });
      setModalVisible(true);
    } catch (error: any) {
      alert(error.message);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setEggData(null);
    refetch();
  };

  return (
    <>
      {/* Egg overlay: the egg is rendered in a fixed container that floats above other content */}
      {/* <div className="fixed inset-0 z-50"> */}
        {eggData && (
          <EggComponent egg={eggData.egg} onClick={handleEggClick} />
        )}
      {/* </div> */}

      {/* Easter Egg Success Modal */}
      {eggData && (
        <EasterEggSuccessModal
          isOpen={modalVisible}
          closeModal={closeModal}
          egg={eggData.egg}
        />
      )}
    </>
  );
};

export default EasterEggHunt;
