import React from "react";

const CircularLoader: React.FC = () => {
    return (
        <div className="flex h-screen items-center justify-center">
            <div className="relative h-12 w-12">
                <div className="absolute inset-0 rounded-full border-t-4 border-gray-300"></div>
                <div className="absolute inset-0 animate-spin rounded-full border-t-4 border-brand duration-[1500ms]"></div>
            </div>
        </div>
    );
};

export default CircularLoader;
