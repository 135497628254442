import { gql } from "@apollo/client";

export const PLAYER_STATS_QUERY = gql`
    query PlayerStatsQuery {
        playerStats {
            bits
            checkins
            clanRank
            giftedSubs
            lifetimeVp
            overallRank
            lifetimeRank
            raids
            seasonVp
            sentryTime
            shieldValue
            wheelOfHamingjaWins
            exalted
            wohTokens
        }
    }
`;

export const PLAYER_SHIELD_TAGS_QUERY = gql`
    query PlayerShieldTagsQuery {
        playerTags {
            id
            tagTier {
                tag
                tier
                tagName
                shieldValue
                subtitle
                unitName
            }
            createdAt
            updatedAt
            imageUrl
        }
    }
`;

export const ALL_SHIELD_TAGS_QUERY = gql`
    query AllShieldTagsQuery {
        allTags {
            name
            slug
            milestones
            maxTiers
            shieldValues
            imageTemplate
            subtitle
            unitName
        }
    }
`;
