import { gql } from "@apollo/client";


// GraphQL query to poll for an egg.
export const POLL_EASTER_EGG_QUERY = gql`
  query PollEasterEgg {
    pollEasterEgg {
      egg {
        id
        name
        image
        imageUrl
        successMessage
      }
      token
      message
    }
  }
`;
