export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

type ClassValue = string | Record<string, boolean> | undefined | null;

export function classNamesExtended(...classes: ClassValue[]): string {
  return classes
    .map((cls) => {
      if (!cls) return "";
      if (typeof cls === "string") {
        return cls;
      }
      if (typeof cls === "object") {
        return Object.entries(cls)
          .filter(([_, value]) => Boolean(value))
          .map(([key]) => key)
          .join(" ");
      }
      return "";
    })
    .filter(Boolean)
    .join(" ");
}

