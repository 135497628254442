// EasterEggSuccessModal.tsx
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Egg } from "@/types/easterEggTypes"; // Adjust path as needed

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    egg: Egg;
}

const EasterEggSuccessModal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    egg,
}) => {
    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-1000" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-brand_charcoal/80 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-brand_charcoal border-4 border-brand p-6 text-left shadow-xl transition-all sm:max-w-sm z-1000">
                                <Dialog.Title className="mb-4 text-2xl font-bold text-brand text-center">
                                    Skal!
                                </Dialog.Title>
                                <div className="flex flex-col items-center">
                                    <img
                                        src={egg.imageUrl}
                                        alt={egg.name}
                                        className="mb-4 h-16 w-16"
                                    />
                                    <p className="text-lg text-brand">
                                        {egg.successMessage}
                                    </p>
                                </div>
                                <div className="mt-6 flex justify-end">
                                    <button
                                        onClick={closeModal}
                                        className="rounded bg-blue-600 px-4 py-2 text-brand hover:bg-blue-700 border-2 border-brand"
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EasterEggSuccessModal;
