import React, { useState, useEffect, useRef, Fragment } from "react";
import { SketchPicker } from "react-color";
import { useMutation } from "@apollo/client";
import { CREATE_SHIELD_MUTATION } from "@/graphql/mutations/forgeMutations";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import { Dialog, Transition } from "@headlessui/react";

interface SVGEditorProps {
    svgContent: string;
}

const SubmitShieldConfirmationModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}> = ({ isOpen, onClose, onConfirm }) => {
    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="bg-brand_charcoal/80 fixed inset-0 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="bg-brand_charcoal border-brand relative z-50 transform overflow-hidden rounded-lg border-4 p-6 text-left shadow-xl transition-all sm:max-w-sm">
                                <Dialog.Title className="text-brand mb-4 text-center text-2xl font-bold">
                                    Skal!
                                </Dialog.Title>
                                <div className="flex flex-col items-center">
                                    <p className="text-brand text-center text-lg">
                                        You are about to submit a shield to
                                        WALLHALLA! Please make sure you double
                                        check your paint work for any missed
                                        sections before submission as this
                                        process is final! Skál!
                                    </p>
                                </div>
                                <div className="mt-6 flex justify-end space-x-2">
                                    <button
                                        onClick={onClose}
                                        className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={onConfirm}
                                        className="rounded bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

const SVGEditor: React.FC<SVGEditorProps> = ({ svgContent }) => {
    const [editableSvg, setEditableSvg] = useState(svgContent);
    const [selectedColor, setSelectedColor] = useState("#ff0000");
    const [history, setHistory] = useState<
        { id: string; previousColor: string }[]
    >([]);
    const [redoHistory, setRedoHistory] = useState<
        { id: string; previousColor: string }[]
    >([]);
    const [recentColors, setRecentColors] = useState<string[]>([
        "#ffffff",
        "#000000",
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#ffff00",
    ]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const svgContainerRef = useRef<HTMLDivElement>(null);
    const selectedColorRef = useRef(selectedColor);
    const navigate = useNavigate();
    const { fetchUserData } = useAuth();

    const [createShield, { loading }] = useMutation(CREATE_SHIELD_MUTATION, {
        onCompleted: async (data) => {
            if (data.createShield.success) {
                try {
                    await fetchUserData(); // Ensure fetchUserData completes before navigating
                    navigate("/dashboard");
                } catch (error) {
                    console.error("Failed to fetch user data:", error);
                    setErrorMessage(
                        "An error occurred while fetching user data.",
                    );
                }
            } else {
                setErrorMessage(
                    data.createShield.message || "An error occurred.",
                );
            }
        },
        onError: (error) => {
            setErrorMessage(error.message || "An unexpected error occurred.");
        },
    });

    useEffect(() => {
        selectedColorRef.current = selectedColor; // Keep the ref updated with the latest color
    }, [selectedColor]);

    useEffect(() => {
        if (!svgContainerRef.current || !editableSvg) return;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(editableSvg, "image/svg+xml");

        const paths = svgDoc.querySelectorAll(".cls-2");
        paths.forEach((path, index) => {
            const id = `path-${index}`;
            path.setAttribute("id", id);

            if (
                !path.hasAttribute("fill") ||
                path.getAttribute("fill") === "none"
            ) {
                path.setAttribute("fill", "#ffffff");
            }

            path.setAttribute(
                "style",
                "cursor: pointer; transition: fill 0.2s ease;",
            );
            path.addEventListener("click", () => handlePathClick(id));
        });

        svgContainerRef.current.innerHTML = "";
        svgContainerRef.current.appendChild(svgDoc.documentElement);
    }, [editableSvg]);

    const handlePathClick = (id: string) => {
        if (!svgContainerRef.current) return;
        const path = svgContainerRef.current.querySelector(`#${id}`);
        if (path) {
            const previousColor = path.getAttribute("fill") || "#ffffff";

            setHistory((prev) => [...prev, { id, previousColor }]);
            setRedoHistory([]); // Clear redo history on a new action

            path.setAttribute("fill", selectedColorRef.current); // Use ref for the latest color

            const svgElement = svgContainerRef.current.querySelector("svg");
            if (svgElement) {
                const serializer = new XMLSerializer();
                setEditableSvg(serializer.serializeToString(svgElement));
            }
        }
    };

    const handleColorChangeComplete = (color: any) => {
        const newColor = color.hex;
        setSelectedColor(newColor);

        setRecentColors((prevColors) => {
            const updatedColors = [
                newColor,
                ...prevColors.filter((c) => c !== newColor),
            ];
            return updatedColors.slice(0, 16); // Limit to 16 recent colors
        });
    };

    const handleUndo = () => {
        const lastChange = history[history.length - 1];
        if (!lastChange || !svgContainerRef.current) return;

        setHistory((prev) => prev.slice(0, prev.length - 1));
        const { id, previousColor } = lastChange;
        const path = svgContainerRef.current.querySelector(`#${id}`);
        if (path) {
            const currentColor = path.getAttribute("fill") || "#ffffff";
            setRedoHistory((prev) => [
                ...prev,
                { id, previousColor: currentColor },
            ]);

            path.setAttribute("fill", previousColor);

            const svgElement = svgContainerRef.current.querySelector("svg");
            if (svgElement) {
                const serializer = new XMLSerializer();
                setEditableSvg(serializer.serializeToString(svgElement));
            }
        }
    };

    const handleRedo = () => {
        const lastRedo = redoHistory[redoHistory.length - 1];
        if (!lastRedo || !svgContainerRef.current) return;

        setRedoHistory((prev) => prev.slice(0, prev.length - 1));
        const { id, previousColor } = lastRedo;
        const path = svgContainerRef.current.querySelector(`#${id}`);
        if (path) {
            const currentColor = path.getAttribute("fill") || "#ffffff";
            setHistory((prev) => [
                ...prev,
                { id, previousColor: currentColor },
            ]);

            path.setAttribute("fill", previousColor);

            const svgElement = svgContainerRef.current.querySelector("svg");
            if (svgElement) {
                const serializer = new XMLSerializer();
                setEditableSvg(serializer.serializeToString(svgElement));
            }
        }
    };

    const handleClear = () => {
        if (!svgContainerRef.current) return;

        const paths = svgContainerRef.current.querySelectorAll("path");
        paths.forEach((path) => {
            path.setAttribute("fill", "#ffffff");
        });

        const svgElement = svgContainerRef.current.querySelector("svg");
        if (svgElement) {
            const serializer = new XMLSerializer();
            setEditableSvg(serializer.serializeToString(svgElement));
        }

        setHistory([]);
        setRedoHistory([]);
    };

    const handleSubmit = () => {
        if (!editableSvg) {
            setErrorMessage("SVG content is missing.");
            return;
        }

        try {
            const base64Svg = `data:image/svg+xml;base64,${btoa(editableSvg)}`;
            createShield({ variables: { imageData: base64Svg } });
        } catch (error) {
            setErrorMessage("Failed to encode the SVG. Please try again.");
        }
    };

    return (
        <div className="mt-20 grid grid-cols-5">
            <div className="border-brand bg-brand_charcoal bg-opacity-70 col-span-3 col-start-2 grid grid-cols-2 gap-4 rounded-lg border-4 p-10">
                {/* SVG Container */}
                <div ref={svgContainerRef} className=""></div>

                {/* Color Picker */}
                <div className="flex flex-col items-center justify-center space-y-4">
                    <SketchPicker
                        color={selectedColor}
                        onChangeComplete={handleColorChangeComplete}
                        presetColors={recentColors}
                        width="65%"
                    />

                    {/* Color Pill */}
                    <div
                        className="h-10 w-10 rounded-full border border-gray-300"
                        style={{ backgroundColor: selectedColor }}
                        title={`Selected Color: ${selectedColor}`}
                    ></div>
                </div>

                {/* Error Alert */}
                {errorMessage && (
                    <div className="col-span-2 mb-4 rounded-sm bg-red-500 p-4 text-white">
                        <p>{errorMessage}</p>
                    </div>
                )}

                {/* Buttons */}
                <div className="col-span-2 flex items-center justify-center space-x-4">
                    {/* Back Button */}
                    <button
                        onClick={() => navigate("/forge")}
                        className="rounded-sm bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
                    >
                        Back
                    </button>
                    <button
                        onClick={handleUndo}
                        className="rounded-sm bg-yellow-500 px-4 py-2 text-white hover:bg-yellow-600"
                    >
                        Undo
                    </button>
                    <button
                        onClick={handleRedo}
                        className="rounded-sm bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                    >
                        Redo
                    </button>
                    <button
                        onClick={handleClear}
                        className="rounded-sm bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                    >
                        Clear
                    </button>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        disabled={loading}
                        className={`rounded px-4 py-2 text-white ${
                            loading
                                ? "cursor-not-allowed bg-gray-400"
                                : "bg-indigo-600 hover:bg-indigo-700"
                        }`}
                    >
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </div>
            {/* Confirmation Modal */}
            <SubmitShieldConfirmationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => {
                    handleSubmit();
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
};

export default SVGEditor;
