import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
    PLAYER_SHIELD_TAGS_QUERY,
    ALL_SHIELD_TAGS_QUERY,
} from "@/graphql/queries/profileQueries";
import { convertLetters } from "@/utils/letterConversion";
import { classNamesExtended } from "@/helpers/common/classNames";

// ----------------------------
// TypeScript Interfaces for Queries
// ----------------------------
interface PlayerTag {
    id: string;
    tagTier: {
        tag: string;
        tier: number;
        tagName: string;
        shieldValue: number;
        subtitle: string;
        unitName: string;
    };
    createdAt: string;
    updatedAt: string;
    imageUrl: string;
}

interface PlayerShieldTagsQueryData {
    playerTags: PlayerTag[];
}

interface AllShieldTag {
    name: string;
    slug: string;
    milestones: number[];
    maxTiers: number;
    shieldValues: number[];
    imageTemplate: string;
    subtitle: string;
    unitName: string;
}

interface AllShieldTagsQueryData {
    allTags: AllShieldTag[];
}

// ----------------------------
// Helper: Fallback Image URL
// ----------------------------
// Replace the "{tier}" placeholder with "1" for a fallback (tier‑1) image.
const getFallbackImageUrl = (tag: AllShieldTag): string => {
    return tag.imageTemplate.replace("{tier}", "1");
};

// ----------------------------
// TagCard Component
// ----------------------------
interface TagCardProps {
    tag: AllShieldTag;
    playerTag?: PlayerTag;
}

const TagCard: React.FC<TagCardProps> = ({ tag, playerTag }) => {
    // Two state variables: one for hover and one for click.
    const [clickedFlipped, setClickedFlipped] = useState(false);
    const [hovered, setHovered] = useState(false);
    // The card is considered flipped if either state is true.
    const flipped = clickedFlipped || hovered;

    // Use the player's image if unlocked; otherwise, use the fallback.
    const imageUrl = playerTag ? playerTag.imageUrl : getFallbackImageUrl(tag);
    const isUnlocked = Boolean(playerTag);
    const currentTier = playerTag ? playerTag.tagTier.tier : 0;
    const nextMilestone = playerTag
        ? currentTier < tag.maxTiers
            ? tag.milestones[currentTier]
            : "Max Tier Reached"
        : tag.milestones[0];
    const currentShieldValue = playerTag ? playerTag.tagTier.shieldValue : null;

    return (
        <div
            className={classNamesExtended("border-brand relative aspect-square cursor-pointer rounded border-4", {
                "border-gray-300": !isUnlocked,
            })}
            style={{ perspective: "1000px" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={(e) => {
                e.stopPropagation();
                setClickedFlipped((prev) => !prev);
            }}
        >
            <div
                className="h-full w-full transform transition-transform duration-700"
                style={{
                    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
                    transformStyle: "preserve-3d",
                }}
            >
                {/* Front Side: Tag Image */}
                <div
                    className="absolute inset-0 flex items-center justify-center p-2"
                    style={{ backfaceVisibility: "hidden" }}
                >
                    <img
                        src={imageUrl}
                        alt={tag.name}
                        className={classNamesExtended("max-h-full max-w-full", {
                            grayscale: !isUnlocked,
                        })}
                    />
                </div>
                {/* Back Side: Tag Details */}
                <div
                    className="bg-brand_charcoal text-brand absolute inset-0 flex flex-col items-center justify-center rounded p-2"
                    style={{
                        backfaceVisibility: "hidden",
                        transform: "rotateY(180deg)",
                    }}
                >
                    <h3 
                        className="text-center font-bold font-prviking text-2xl"
                        style={
                            {
                                textDecorationLine: "underline overline",
                                // Offser the underline down a bit.
                                textDecorationThickness: "0.1em",
                                textUnderlineOffset: "0.2em",
                            }
                        }
                    >
                        {convertLetters(tag.name)}
                    </h3>
                    <h4 className="text-center text-lg text-stats font-bold">
                        {tag.subtitle}
                    </h4>
                    <div className="text-left">
                    {playerTag ? (
                        <>
                            <p>
                                <strong>CURRENT TIER:</strong> <span className="text-stats">{currentTier}</span>
                            </p><p>
                                <strong>SHIELD VALUE:</strong> <span className="text-stats">{currentShieldValue}</span>
                            </p>
                            {nextMilestone === "Max Tier Reached" ? (
                                <p>
                                    <strong>MAX TIER REACHED</strong>
                                </p>
                            ) : (
                                <p>
                                    <strong>NEXT TIER:</strong> <span className="text-stats">{nextMilestone} {tag.unitName}</span>
                                </p>
                            )}
                            
                        </>
                    ) : (
                        <p>
                            <strong>NEXT TIER:</strong> <span className="text-stats">{nextMilestone} {tag.unitName}</span>
                        </p>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// ----------------------------
// ShieldTagsCabinet Component
// ----------------------------
const ShieldTagsCabinet: React.FC = () => {
    const {
        data: playerTagsData,
        loading: playerTagsLoading,
        error: playerTagsError,
    } = useQuery<PlayerShieldTagsQueryData>(PLAYER_SHIELD_TAGS_QUERY, {
        pollInterval: 5000,
    });
    const {
        data: allTagsData,
        loading: allTagsLoading,
        error: allTagsError,
    } = useQuery<AllShieldTagsQueryData>(ALL_SHIELD_TAGS_QUERY, {
        pollInterval: 10000,
    });

    if (playerTagsLoading || allTagsLoading) return <p>Loading...</p>;
    if (playerTagsError || allTagsError)
        return (
            <p>
                Error loading tags:{" "}
                {playerTagsError?.message || allTagsError?.message}
            </p>
        );

    // Build a lookup map for unlocked tags keyed by tag slug.
    const playerTagsMap: Record<string, PlayerTag> = {};
    playerTagsData?.playerTags.forEach((shieldTag) => {
        playerTagsMap[shieldTag.tagTier.tag] = shieldTag;
    });
    const allTags: AllShieldTag[] = allTagsData?.allTags || [];

    return (
        <div className="shield-tags-cabinet">
            <div className="grid grid-cols-6 gap-4">
                {allTags.map((tag) => (
                    <TagCard
                        key={tag.slug}
                        tag={tag}
                        playerTag={playerTagsMap[tag.slug]}
                    />
                ))}
            </div>
        </div>
    );
};

export default ShieldTagsCabinet;
