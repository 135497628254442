import { useContext } from "react";
import { AuthContext } from "@/contexts/AuthContext";
import { SiTwitch } from "@icons-pack/react-simple-icons";

const HomePage: React.FC = () => {
    const {startTwitchLogin} = useContext(AuthContext);

    return (
        <div className="relative h-screen w-full">
            {/* Centered Content Wrapper */}
            <div className="relative z-10 flex items-center justify-center h-full w-full">
                <div className="text-center">
                    <div className="mb-2">
                        <h2
                            className="font-norse text-9xl text-brand drop-shadow-lg"
                            style={{
                                WebkitTextStroke: "4px black",
                            }}
                        >
                            The Vanderheim Village
                        </h2>
                    </div>
                    <button
                        className="bg-[#6441A4] text-white px-4 py-2 rounded-md"
                        onClick={startTwitchLogin}
                    >
                        <SiTwitch className="inline-block mr-2" />
                        Login with Twitch
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
