import { gql } from "@apollo/client";


// GraphQL mutation to claim an egg.
export const CLAIM_EASTER_EGG_MUTATION = gql`
  mutation ClaimEasterEgg($instanceId: ID!) {
    claimEasterEgg(instanceId: $instanceId) {
      message
    }
  }
`;
