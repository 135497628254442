import { useAuth } from "@/hooks/auth/useAuth";
import { convertLetters, convertLettersSafely } from "@/utils/letterConversion";
import NamePlateComponent from "@/components/dashboard/general/svgs/NamePlateComponent";
import { Link } from "react-router-dom";

export default function DashboardPage() {
    const { user } = useAuth();

    const title = convertLetters("Welcome to <br/> Vanderheim");
    const twitchUsername = user
        ? convertLettersSafely(user?.twitchUsername)
        : "";
    const shield = user?.player?.shield || null;

    return (
        <>
            <div className="mt-5 grid gap-1 sm:grid-cols-4">
                {/* Align vertically and horizontally center */}
                <div className="col-span-4 flex items-center justify-center justify-items-center pt-10">
                    <h1
                        className="font-prviking border-text text-center text-9xl text-brand drop-shadow-lg"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </div>
                <div className="col-span-4 flex items-center justify-center justify-items-center">
                    <h2 className="font-prviking border-text text-center text-7xl text-brand drop-shadow-lg">
                        {twitchUsername && (
                            <span>
                                <br />
                                {twitchUsername}
                            </span>
                        )}
                    </h2>
                </div>

                {/* So what I want is essentially four squares which will be 4 clickable image buttons.
                    The sections will be:

                    - Profile
                    - Wallhalla
                    - Leaderboards
                    - Marketplace (Greyed out/Coming Soon)
                */}

                {/* Profile */}
                <div className="col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <Link to="profile">
                                <img
                                    src={shield?.imageWithTagsUrl || shield?.imageUrl}
                                    alt="Profile"
                                    className="h-80 w-80 rounded-lg border-8 border-brand"
                                    style={{
                                        backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                    }}
                                />
                                <NamePlateComponent
                                    text="Profile"
                                    className="absolute bottom-[-24px] left-1/2 z-10 -translate-x-1/2 transform"
                                    containerWidth={300}
                                    overrideFontSize={true}
                                    defaultFontSize={30}
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Wallhalla */}
                <div className="col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <Link to="https://wallhalla.vanderheim.com">
                                <img
                                    src="/wallhalla.png"
                                    alt="Wallhalla"
                                    className="h-80 w-80 rounded-lg border-8 border-brand"
                                />
                                <NamePlateComponent
                                    text="Wallhalla"
                                    className="absolute bottom-[-24px] left-1/2 z-10 -translate-x-1/2 transform"
                                    containerWidth={300}
                                    overrideFontSize={true}
                                    defaultFontSize={30}
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Leaderboards
                <div className="col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <Link to="leaderboards">
                                <img
                                    src="/leaderboards.png"
                                    alt="Leaderboards"
                                    className="h-80 w-80 rounded-lg border-8 border-brand"
                                />
                                <NamePlateComponent
                                    text="Leaderboards"
                                    className="absolute bottom-[-24px] left-1/2 z-10 -translate-x-1/2 transform"
                                    containerWidth={300}
                                    overrideFontSize={true}
                                    defaultFontSize={30}
                                />
                            </Link>
                        </div>
                    </div>
                </div> */}

                {/* Inactive Leaderboard */}
                <div className="col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <img
                                src="/leaderboards.png"
                                alt="Leaderboards"
                                className="h-80 w-80 rounded-lg border-8 border-brand opacity-90 grayscale filter"
                            />
                            <NamePlateComponent
                                text="Leaderboards"
                                className="absolute bottom-[-24px] left-1/2 z-10 -translate-x-1/2 transform"
                                containerWidth={300}
                                overrideFontSize={true}
                                defaultFontSize={30}
                                style={{
                                    filter: "grayscale(100%)",
                                }}
                            />
                            <div className="absolute inset-0 rounded-lg bg-gray-500 opacity-30"></div>
                        </div>
                    </div>
                </div>

                {/* Marketplace */}
                <div className="col-span-4 sm:col-span-1">
                    <div className="flex items-center justify-center">
                        <div className="relative">
                            <img
                                src="/viking-marketplace.webp"
                                alt="Marketplace"
                                className="h-80 w-80 rounded-lg border-8 border-brand opacity-90 grayscale filter"
                            />
                            <NamePlateComponent
                                text="Marketplace"
                                className="absolute bottom-[-24px] left-1/2 z-10 -translate-x-1/2 transform"
                                containerWidth={300}
                                overrideFontSize={true}
                                defaultFontSize={30}
                                style={{
                                    filter: "grayscale(100%)",
                                }}
                            />
                            <div className="absolute inset-0 rounded-lg bg-gray-500 opacity-30"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
