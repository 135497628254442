import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const AlreadyHasShieldGuard = ({ children }: GuardProps) => {
    console.log("Executing AlreadyHasShieldGuard");

    const { user, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing AlreadyHasShieldGuard useEffect");

        // Check if the user.player.shield exists, if it does then navigate to /dashboard
        // If user.player doesn't exist then navigate to /subscribe
        if (!loading && user) {
            if (!user.player) {
                navigate("/subscribe");
            } else if (user.player.shield) {
                navigate("/dashboard");
            }
        }
    }, [user, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return <>{children}</>;
};

export default AlreadyHasShieldGuard;