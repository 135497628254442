import { useState } from "react";
import { useAuth } from "@/hooks/auth/useAuth";
import { useQuery } from "@apollo/client";
import { PLAYER_STATS_QUERY } from "@/graphql/queries/profileQueries";
import NamePlateComponent from "@/components/dashboard/general/svgs/NamePlateComponent";
import { convertLetters } from "@/utils/letterConversion";
import ShieldTagsCabinet from "@/components/dashboard/profile/ShiieldTagsCabinet";

export default function ProfilePage() {
    const [activeTab, setActiveTab] = useState(0);
    const { user } = useAuth();
    const shield = user?.player?.shield || null;
    const playerName = user?.player?.name || "";
    const clanBorderColor = user?.player?.clan?.borderColor?.hex || "#ffcc33";
    const clanRankColor = user?.player?.clan?.rankColor?.hex || "#ffcc33";
    const clanRankNumberColor =
        user?.player?.clan?.rankNumberColor?.hex || "#FDFABB";
    const overallMonthsSubscribed = Math.floor((user?.player?.overallSubTimeInDays ?? 0) / 30);
    const overallMonthsInGota = Math.floor((user?.player?.gotaSubTimeInDays ?? 0) / 30);

    // Fetch player stats
    const { data, loading, error } = useQuery(PLAYER_STATS_QUERY, {
        pollInterval: 5000,
    });

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    const totalStats = [
        {
            name: "Shield Value",
            stat: data?.playerStats?.shieldValue.toString(),
            prefix: "",
            suffix: "",
        },
        {
            name: "Lifetime VP Rank",
            stat: data?.playerStats?.lifetimeRank.toString(),
            prefix: "",
            suffix: "",
        },
        {
            name: "Season Rank",
            stat: data?.playerStats?.overallRank.toString(),
            prefix: "",
            suffix: "",
        },
        {
            name: user?.player?.clan?.tag + " Rank",
            stat: data?.playerStats?.clanRank.toString(),
            prefix: "",
            suffix: "",
        },
    ];

    const stats = [
        {
            title: "Lifetime VP",
            value: data?.playerStats?.lifetimeVp || 0,
            color: "border-brand",
        },
        {
            title: "Season VP",
            value: data?.playerStats?.seasonVp || 0,
            color: "border-brand",
        },
        {
            title: "Checkins",
            value: data?.playerStats?.checkins || 0,
            color: "border-checkins",
        },
        {
            title: "Raids",
            value: data?.playerStats?.raids || 0,
            color: "border-raids",
        },
        {
            title: "Sentry Time",
            // Divide by 60 to get hours
            value: (data?.playerStats?.sentryTime / 60).toString() + " hours",
            color: "border-sentry",
        },
        {
            title: "Bits",
            value: data?.playerStats?.bits || 0,
            color: "border-rainbow",
        },
        {
            title: "Gifted Subs",
            value: data?.playerStats?.giftedSubs || 0,
            color: "border-gifted",
        },
        {
            title: "Wheel of Hamingja",
            value: data?.playerStats?.wheelOfHamingjaWins || 0,
            color: "border-hamingja",
        },
        {
            title: "Exalted",
            value: data?.playerStats?.exalted || 0,
            color: "border-exalted",
        },
        {
            title: "WOH Tokens",
            value: data?.playerStats?.wohTokens || 0,
            color: "border-hamingja",
        },
        {
            title: "Months Subscribed",
            value: overallMonthsSubscribed,
            color: "border-brand",
        },
    ];

    // Only show Months in Gota if the player has been in Gota
    if (overallMonthsInGota > 0) {
        stats.push({
            title: "Months in Gota",
            value: overallMonthsInGota,
            color: "border-brand",
        });
    }

    const tabs = [
        {
            title: "Shield Tags",
            content: <ShieldTagsCabinet />,
            enabled: true,
        },
        {
            title: "Inventory",
            content: <div>Content for Tab 2</div>,
            enabled: false,
        },
        {
            title: "History",
            content: <div>Content for Tab 3</div>,
            enabled: false,
        },
        {
            title: "YGGY",
            content: <div>Content for Tab 4</div>,
            enabled: false,
        },
    ];

    return (
        // Outer container using min-h-screen (or remove height if not needed)
        <div className="flex min-h-screen items-center justify-center">
            <div className="w-full p-4">
                {/* Remove h-full from the grid so the left column only grows as needed */}
                <div className="grid grid-cols-4 gap-4">
                    {/* Left Column */}
                    <div className="border-brand col-span-1 flex flex-col border-4 bg-black/70 pb-4">
                        <div className="relative">
                            <img
                                src={shield?.imageWithTagsUrl || shield?.imageUrl}
                                alt="Profile"
                                className="border-b-brand border-b-4 pb-2"
                                style={{
                                    backgroundImage: `url(/wallhalla-blank-wall.png)`,
                                }}
                            />
                            <NamePlateComponent
                                text={playerName}
                                className="absolute bottom-[8px] left-1/2 z-10 -translate-x-1/2 transform"
                            />
                        </div>
                        {/* Removed flex-grow so this section takes only natural height */}
                        <div className="mt-5 grid grid-cols-2 gap-4 px-4">
                            {stats.map((stat, index) => (
                                <div
                                    key={index}
                                    className={
                                        `overflow-hidden rounded-lg border-4 bg-black/70 px-4 py-2 text-center ` +
                                        stat.color
                                    }
                                >
                                    <dt className="text-l text-brand font-prviking font-medium">
                                        {convertLetters(stat.title)}
                                    </dt>
                                    <dd className="text-stats mt-1 text-2xl font-garamond">
                                        {stat.value}
                                    </dd>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Column (spanning 3 columns) */}
                    <div className="col-span-3 flex flex-col">
                        <dl className="grid grid-cols-2 gap-5 pb-4 sm:grid-cols-4">
                            {totalStats.map((item, index) => {
                                const isLast = index === totalStats.length - 1;
                                return (
                                    <div
                                        key={item.name}
                                        className={`overflow-hidden rounded-lg border-4 bg-black/80 px-4 py-5 shadow-sm sm:p-6 ${
                                            !isLast && "border-brand"
                                        }`}
                                        style={
                                            isLast
                                                ? {
                                                      borderColor:
                                                          clanBorderColor,
                                                  }
                                                : {}
                                        }
                                    >
                                        <dt
                                            className={`font-prviking truncate text-center text-3xl ${
                                                !isLast && "text-brand"
                                            }`}
                                            style={
                                                isLast
                                                    ? { color: clanRankColor }
                                                    : {}
                                            }
                                        >
                                            {convertLetters(item.name)}
                                        </dt>
                                        <dd
                                            className={`mt-1 text-center text-3xl ${!isLast && "text-stats font-garamond"}`}
                                            style={
                                                isLast
                                                    ? {
                                                          color: clanRankNumberColor,
                                                      }
                                                    : {}
                                            }
                                        >
                                            {item.prefix}
                                            {item.stat}
                                            {item.suffix}
                                        </dd>
                                    </div>
                                );
                            })}
                        </dl>

                        <div className="border-brand flex-grow border-4 bg-black/70">
                            {/* Tab Headers */}
                            <div className="mb-4">
                                <nav className="flex w-full">
                                    {tabs.map((tab, index) => {
                                        const borderRightClass =
                                            index < tabs.length - 1
                                                ? " border-r-4 border-r-brand"
                                                : "";
                                        const isDisabled = !tab.enabled;
                                        return (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    if (!isDisabled) {
                                                        setActiveTab(index);
                                                    }
                                                }}
                                                disabled={isDisabled}
                                                className={`font-prviking flex-1 border-b-4 py-3 text-center text-3xl font-medium border-b-brand${borderRightClass} ${
                                                    activeTab === index
                                                        ? "bg-brand text-brand_charcoal"
                                                        : isDisabled
                                                          ? "bg-transparent text-gray-400"
                                                          : "text-brand hover:bg-brand hover:text-brand_charcoal bg-transparent"
                                                } ${isDisabled ? "cursor-not-allowed opacity-50" : ""}`}
                                            >
                                                {convertLetters(tab.title)}
                                            </button>
                                        );
                                    })}
                                </nav>
                            </div>

                            {/* Tab Content */}
                            <div className="px-4">
                                {tabs[activeTab].content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
