"use client";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { toggleFullScreen } from "@/helpers/common/toggleFullScreen";
import { BellIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import IconComponent from "@/components/icon/IconComponent";
import InitialsComponent from "@/components/dashboard/general/svgs/InitialsComponent";
import { Link } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import { classNames } from "@/helpers/common/classNames";
import LogoComponent from "@/components/logo/LogoComponent";

const TopNav: React.FC = () => {
    const { user, logoutUser } = useAuth();
    const userName = user?.twitchUsername || "John Doe";
    const myAccountLink = `/dashboard/account`;
    const avatarUrl = user?.avatarUrl || null;
    const player = user?.player || null;
    const clan = player?.clan || null;
    const clanColor = clan?.color?.hex || "#000000";
    const moderator = player?.moderator || false;
    const gota = player?.isGota || false;
    const vip = player?.vip || false;
    const vanderbucks = player?.bux?.balance || 0;
    const goldenWohToken = player?.goldenWohToken || false;

    console.log("Clan Color:", clanColor);

    return (
        <>
            <div className="border-b-brand bg-brand_charcoal sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b-2 pr-4 shadow-xs sm:gap-x-6 sm:pr-6 lg:pr-8">
                {/* These should be aligned to the right. */}
                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    {/* Make link take height of parent div */}
                    <Link to="/dashboard" className="h-full items-center p-4">
                        <LogoComponent className="h-full" />
                    </Link>
                    <div className="ml-auto flex items-center gap-x-4 lg:gap-x-6">
                        {gota && (
                            <div
                                className={`text-gota bg-gotabone hidden items-center rounded-xl px-2 py-1 text-xs leading-4 font-semibold lg:flex`}
                            >
                                GOTA
                            </div>
                        )}

                        {/* We show a pill saying "Moderator" if the user is a moderator */}
                        {/* It will use the twitch moderator green color. */}

                        {moderator && (
                            <div
                                className={`hidden items-center rounded-xl px-2 py-1 text-xs leading-4 font-semibold lg:flex`}
                                style={{ backgroundColor: "#00FF00" }}
                            >
                                MOD
                            </div>
                        )}

                        {vip && (
                            <div
                                className={`hidden items-center rounded-xl px-2 py-1 text-xs leading-4 font-semibold lg:flex`}
                                style={{ backgroundColor: "#E005B9" }}
                            >
                                VIP
                            </div>
                        )}

                        {/* We show a pill saying "VIP" if the user is a VIP */}
                        {/* It will use the twitch VIP purple color. */}

                        {/* We show a pill with with clan tag and bg as the clan color */}
                        {clan && (
                            <div
                                className={`hidden items-center rounded-xl px-2 py-1 text-xs leading-4 font-semibold lg:flex`}
                                style={{ backgroundColor: clanColor }}
                            >
                                {clan.tag}
                            </div>
                        )}

                        {goldenWohToken && (
                        <>
                        {/* Seperator */}
                        <div
                            className="bg-brand hidden lg:block lg:h-full lg:w-px"
                            aria-hidden="true"
                        />

                        <div className="flex items-center gap-x-2 lg:gap-x-4">
                            <div className="h-10">
                                {/* adjust width/height as needed */}
                                <img
                                    src="/images/golden_woh_token.png"
                                    alt="Golden WOH Token"
                                    className="h-full w-full object-contain"
                                />
                            </div>
                        </div>
                        </>
                        )}

                        {/* Seperator */}
                        <div
                            className="bg-brand hidden lg:block lg:h-full lg:w-px"
                            aria-hidden="true"
                        />

                        <div className="flex items-center gap-x-2">
                            <div className="h-8">
                                {/* adjust width/height as needed */}
                                <img
                                    src="/images/vanderbucks.png"
                                    alt="Vanderbucks"
                                    className="h-full w-full object-contain"
                                />
                            </div>
                            <div className="text-stats text-lg font-semibold">
                                <span>{vanderbucks}</span>
                            </div>
                        </div>

                        {/* Seperator */}
                        <div
                            className="bg-brand hidden lg:block lg:h-full lg:w-px"
                            aria-hidden="true"
                        />

                        <button
                            type="button"
                            className="text-brand hover:text-brand -m-2.5 p-2.5"
                        >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <button
                            type="button"
                            onClick={toggleFullScreen}
                            className="text-brand hover:text-brand -m-2.5 hidden p-2.5 md:block"
                        >
                            <span className="sr-only">Full Screen</span>
                            <ViewfinderCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>

                        {/* Separator */}
                        <div
                            className="bg-brand hidden lg:block lg:h-full lg:w-px"
                            aria-hidden="true"
                        />

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                {avatarUrl ? (
                                    <img
                                        className="border-brand h-8 w-8 rounded-full border-2"
                                        src={avatarUrl}
                                        alt=""
                                    />
                                ) : (
                                    <InitialsComponent name={userName} />
                                )}
                                <span className="hidden lg:flex lg:items-center">
                                    <span
                                        className="text-brand ml-4 text-sm leading-6 font-semibold"
                                        aria-hidden="true"
                                    >
                                        {userName}
                                    </span>
                                    <IconComponent
                                        iconName="chevron-down-solid-20"
                                        className="text-brand ml-2 h-5 w-5"
                                        aria-hidden={true}
                                    />
                                </span>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="ring-brand bg-brand_charcoal absolute -right-3.5 z-10 mt-2.5 w-44 origin-top-right rounded-md py-2 shadow-lg ring-1 focus:outline-hidden">
                                    <Menu.Item>
                                        <Link
                                            to={myAccountLink}
                                            className={classNames(
                                                "text-brand block px-3 py-1 text-sm leading-6",
                                            )}
                                        >
                                            Account
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to="#"
                                            onClick={logoutUser}
                                            className={classNames(
                                                "text-brand block px-3 py-1 text-sm leading-6",
                                            )}
                                        >
                                            Sign Out
                                        </Link>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopNav;
