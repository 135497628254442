import TopNav from "@/components/dashboard/general/navigation/TopNav";


import { ReactNode } from "react";

export default function BaseLayout({ children }: { children: ReactNode }) {
    return (
        <div className="h-full flex flex-col">
            {/* Top Navigation */}
            <div className="fixed top-0 z-50 w-full">
                <TopNav />
            </div>

            {/* Main Content */}
            <main className="flex-1 pt-16 overflow-auto">
                <div className="px-4 sm:px-6 lg:px-8">
                    {children}
                </div>
            </main>
        </div>
    );
}
