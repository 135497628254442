import { useState, useEffect, useCallback, useRef } from "react";
import { useApolloClient, gql } from "@apollo/client";
import isEqual from "lodash.isequal";
import { UserData } from "@/types/globalTypes";
import { router } from "@/router/browserRouter";
import {
    START_TWITCH_AUTH_MUTATION,
    COMPLETE_TWITCH_AUTH_MUTATION,
    LOGIN_USER_MUTATION,
    LOGOUT_USER_MUTATION,
} from "@/graphql/mutations/authMutations";
import { AuthContext } from "@/contexts/AuthContext";

const USER_QUERY = gql`
    query UserQuery {
        isAuthenticated
        user {
            id
            twitchUsername
            email
            avatarUrl
            isStaff
            isSuperuser
            player {
                id
                name
                vip
                twitchId
                tagOfVanderwood
                goldenWohToken
                permanentVip
                nickname
                moderator
                enabled
                isGota
                gotaSubTimeInDays
                overallSubTimeInDays
                clan {
                    id
                    name
                    tag
                    twitchEmojiName
                    color {
                        hex
                    }
                    borderColor {
                        hex
                    }
                    rankNumberColor {
                        hex
                    }
                    rankColor {
                        hex
                    }
                }
                shield {
                    id
                    shieldNumber
                    imageUrl
                    imageWithTagsUrl
                    createdAt
                    updatedAt
                }
                bux {
                    balance
                }
            }
        }
    }
`;

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<UserData | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const client = useApolloClient();
    const { navigate } = router;
    
    // Ref to mark when the initial fetch is done so that we only use the loading state once.
    const initialFetchDone = useRef(false);

    const fetchUserData = useCallback(async () => {
        // Only set loading true for the initial fetch.
        if (!initialFetchDone.current) {
            setLoading(true);
        }
        try {
            const { data } = await client.query({
                query: USER_QUERY,
                fetchPolicy: "network-only",
            });
            if (data.isAuthenticated) {
                // Only update state if the user data or auth status has changed.
                if (!isEqual(data.user, user) || isAuthenticated !== data.isAuthenticated) {
                    setUser(data.user);
                    setIsAuthenticated(data.isAuthenticated);
                }
            } else {
                if (user !== null || isAuthenticated !== false) {
                    setUser(null);
                    setIsAuthenticated(false);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            // Optionally clear state on error.
            if (user !== null || isAuthenticated !== false) {
                setUser(null);
                setIsAuthenticated(false);
            }
        } finally {
            if (!initialFetchDone.current) {
                setLoading(false);
                initialFetchDone.current = true;
            }
        }
    }, [client, user, isAuthenticated]);

    const startTwitchLogin = async () => {
        try {
            const { data } = await client.mutate({
                mutation: START_TWITCH_AUTH_MUTATION,
            });
            if (data.startTwitchAuth.url) {
                window.location.href = data.startTwitchAuth.url;
            }
        } catch (error) {
            console.error("Error starting Twitch login:", error);
        }
    };

    const completeTwitchLogin = async (code: string) => {
        try {
            const { data } = await client.mutate({
                mutation: COMPLETE_TWITCH_AUTH_MUTATION,
                variables: { code },
            });
            if (data.completeTwitchAuth.user) {
                await fetchUserData(); // Refresh user data after login
                navigate("/forge");
            }
        } catch (error) {
            console.error("Error completing Twitch login:", error);
        }
    };

    const loginUser = async (email: string, password: string) => {
        try {
            const { data } = await client.mutate({
                mutation: LOGIN_USER_MUTATION,
                variables: { email, password },
            });
            if (data.loginUser.success) {
                console.log("Login successful");
                await fetchUserData(); // Refresh user data after login
                navigate("/dashboard"); // Redirect after login success
            } else {
                throw new Error(data.loginUser.message);
            }
        } catch (error) {
            console.error("Error logging in user:", error);
            throw error;
        }
    };

    const logoutUser = async () => {
        try {
            const { data } = await client.mutate({
                mutation: LOGOUT_USER_MUTATION,
            });
            if (data.logoutUser.success) {
                setUser(null);
                setIsAuthenticated(false);
                navigate("/");
            }
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    // Set up polling using setInterval; clear it on component unmount.
    useEffect(() => {
        // Initial fetch on mount.
        fetchUserData();
        const interval = setInterval(() => {
            fetchUserData();
        }, 5000);

        return () => clearInterval(interval);
    }, [fetchUserData]);

    return (
        <AuthContext.Provider
            value={{
                user,
                loading,
                isAuthenticated,
                fetchUserData,
                startTwitchLogin,
                completeTwitchLogin,
                loginUser,
                logoutUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
