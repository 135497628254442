import React from "react";
import { useNavigate } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import { convertLetters } from "@/utils/letterConversion";

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/dashboard"); // Navigate back to the home page
    };

    return (
        <div
            className="relative bg-cover bg-center bg-no-repeat h-screen w-full"
            // style={{ backgroundImage: "url('/wallhalla-blank-wall.png')" }}
        >
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Centered Content */}
            <div className="relative z-10 flex flex-col items-center justify-center h-full w-full text-center px-4">
                <div className="mb-6">
                    <LogoComponent className="mx-auto h-20 w-auto" />
                </div>
                <h2
                    className="font-norse text-6xl md:text-7xl text-brand mb-4"
                    style={{ WebkitTextStroke: "2px black" }}
                >
                    404 - Page Not Found
                </h2>
                <p className="text-7xl text-brand mb-8 font-norse"
                    style={{ WebkitTextStroke: "2px black" }}
                >
                    Oops! The page you are looking for doesn't exist or has been moved.
                </p>
                <button
                    onClick={handleGoHome}
                    className="rounded-md bg-brand px-6 py-2 text-brand_charcoal focus:outline-hidden"
                >
                    Go to Home
                </button>
            </div>
        </div>
    );
};

export default NotFoundPage;
